<template>
  <review-card :key="`list-groupstatus-${expanded}`" title="Pares impactados">
    <v-list-group
      v-for="(item, index) in pares"
      :key="`list-group-par-${index}`"
      no-action
      :value="expanded"
    >
      <template v-slot:activator>
        <v-list-item class="py-2 px-0 item-par">
          <v-list-item-content>
            <v-list-item-title class="body-2">
              {{ item.par.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <div class="breakBeforeClass">
        <tracking-impaction-point-view-sides
          :tracking-point="item.par.points.trackingPoint"
          :impaction-point="item.par.points.impactionPoint"
          :tracking-point-side="item.trackingPointSide"
          :impaction-point-side="item.impactionPointSide"
          class="grey"
        />
        <div class="ml-3 mt-2 body-2">
          <property-value property="Tipo" :value="item.par.type.name" />
          <property-value
            property="Esconder ao paciente"
            :value="item.hidePatient | booleanToString"
          />
          <property-value
            property="Recomendar ao paciente impactar em casa"
            :value="item.patientImpactAtHome | booleanToString"
          />
          <property-value
            property="Comentários aos terapeutas"
            :value="item.therapistComments"
          />
          <property-value
            property="Comentários ao paciente"
            :value="item.patientComments"
          />
          <property-value
            property="Sintomas"
            :value="item.symptoms | arrayToString"
          />
          <property-value
            property="Patógenos"
            :value="item.pathogens | arrayToString"
          />
        </div>
      </div>
    </v-list-group>
  </review-card>
</template>

<script>
export default {
  props: {
    pares: {
      type: Array,
      default: () => [],
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isBreakpointXs() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>

<style></style>
